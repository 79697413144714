// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    box-sizing: border-box;
}

a {
    overflow-wrap: anywhere;
}

a:link {
    color: #FFFFFF;
    text-decoration: none;
    cursor: pointer;
}

a:visited {
    color: #FFF0F0;
    text-decoration: none;
    cursor: pointer;
}

html, body {
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;    margin:0;
    width:100%;
    background-color: #3F3F4A;
}

.App {
    height: 100vh;
    display:flex;
    flex-direction:column;
}

.App .app-content {
    height:100%;
    
}

.App .app-content div {
    animation: pageFadeInAnimation ease 0.75s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes pageFadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
}

@media only screen and (max-width: 600px) {
    .App .app-content {
        margin-bottom: 4rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/css/App.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;AAC1B;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,cAAc;IACd,qBAAqB;IACrB,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,qBAAqB;IACrB,eAAe;AACnB;;AAEA;IACI,2HAA2H,KAAK,QAAQ;IACxI,UAAU;IACV,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,qBAAqB;AACzB;;AAEA;IACI,WAAW;;AAEf;;AAEA;IACI,yCAAyC;IACzC,4BAA4B;IAC5B,6BAA6B;AACjC;;AAEA;IACI;QACI,UAAU;IACd;IACA;QACI,UAAU;KACb;AACL;;AAEA;IACI;QACI,mBAAmB;IACvB;AACJ","sourcesContent":["* {\n    box-sizing: border-box;\n}\n\na {\n    overflow-wrap: anywhere;\n}\n\na:link {\n    color: #FFFFFF;\n    text-decoration: none;\n    cursor: pointer;\n}\n\na:visited {\n    color: #FFF0F0;\n    text-decoration: none;\n    cursor: pointer;\n}\n\nhtml, body {\n    font-family: \"HelveticaNeue-Light\", \"Helvetica Neue Light\", \"Helvetica Neue\", Helvetica, Arial, \"Lucida Grande\", sans-serif;    margin:0;\n    width:100%;\n    background-color: #3F3F4A;\n}\n\n.App {\n    height: 100vh;\n    display:flex;\n    flex-direction:column;\n}\n\n.App .app-content {\n    height:100%;\n    \n}\n\n.App .app-content div {\n    animation: pageFadeInAnimation ease 0.75s;\n    animation-iteration-count: 1;\n    animation-fill-mode: forwards;\n}\n\n@keyframes pageFadeInAnimation {\n    0% {\n        opacity: 0;\n    }\n    100% {\n        opacity: 1;\n     }\n}\n\n@media only screen and (max-width: 600px) {\n    .App .app-content {\n        margin-bottom: 4rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
