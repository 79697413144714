// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.btn {
    border-style: solid;
    border-color: #837483;
    border-radius: 5px;
    border-width: 1px;
    background-color: #373747;
    padding: 6px;
    display: flex;
    text-align: center;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: -moz-fit-content;
    width: fit-content;
    cursor: pointer;
}

.btn:hover {
    color: #C3C0C0;
    background-color: #363643;
    border-color: #C3C0C0;
}

.btn.toggle {
    font-size: 32px;
}

.btn.toggle .on {
    display: block;
    height: 32px;
}

.btn.toggle .off {
    display:none;
}

.btn.mar-la {
    margin-left: auto;
}

.btn.mar-ra {
    margin-right: auto;
}

.btn-compact-container {
    display:flex;
    flex-direction: row;
}

.btn.btn-compact-right {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.btn.btn-compact-left {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/css/Buttons.css"],"names":[],"mappings":";AACA;IACI,mBAAmB;IACnB,qBAAqB;IACrB,kBAAkB;IAClB,iBAAiB;IACjB,yBAAyB;IACzB,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,mBAAmB;IACnB,qBAAqB;IACrB,uBAAuB;IACvB,uBAAkB;IAAlB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,yBAAyB;IACzB,qBAAqB;AACzB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,4BAA4B;AAChC;;AAEA;IACI,0BAA0B;IAC1B,6BAA6B;AACjC","sourcesContent":["\n.btn {\n    border-style: solid;\n    border-color: #837483;\n    border-radius: 5px;\n    border-width: 1px;\n    background-color: #373747;\n    padding: 6px;\n    display: flex;\n    text-align: center;\n    align-items: center;\n    align-content: center;\n    justify-content: center;\n    width: fit-content;\n    cursor: pointer;\n}\n\n.btn:hover {\n    color: #C3C0C0;\n    background-color: #363643;\n    border-color: #C3C0C0;\n}\n\n.btn.toggle {\n    font-size: 32px;\n}\n\n.btn.toggle .on {\n    display: block;\n    height: 32px;\n}\n\n.btn.toggle .off {\n    display:none;\n}\n\n.btn.mar-la {\n    margin-left: auto;\n}\n\n.btn.mar-ra {\n    margin-right: auto;\n}\n\n.btn-compact-container {\n    display:flex;\n    flex-direction: row;\n}\n\n.btn.btn-compact-right {\n    border-top-left-radius: 0;\n    border-bottom-left-radius: 0;\n}\n\n.btn.btn-compact-left {\n    border-top-right-radius: 0;\n    border-bottom-right-radius: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
