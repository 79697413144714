// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.article {
    color: white;
    align-items: center;
    display: flex;
    flex-direction: column;
    max-height:0;
    overflow: hidden;
    transition: max-height 1s ease;

}

.article.open {
    max-height:100vh;
    transition: max-height 1s ease;
}

.article.close {
    max-height:100vh;
}

.article.show {
    max-height:none;

}

.article .content {
    width: 80vw;
    text-align:left;
    line-height: 1.5;
}

.article .section-title {
    font-size: xx-large;
    font-weight: bold;
    margin-top: 1rem;
    text-align: center;
}

.article .code {
    padding: 10px;
    background-color: #3A3B3B;
    color: lightgray;
    border-radius: 5px;
    margin: 20px;
}

.article h2 {
    font-size: 24px;
    font-weight:bolder;
    margin-top: 20px;
    margin-bottom: 10px;
}

.article li {
    margin-left: 2rem;
}

.article a {
    color: #ACACFF;
}

.Articles {
    display:flex;
    flex-direction:column;
    color: #AF8D8C;
}

.Articles h1 {
    margin-left:auto;
    margin-right:auto;
    

}`, "",{"version":3,"sources":["webpack://./src/components/css/Articles.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,gBAAgB;IAChB,8BAA8B;;AAElC;;AAEA;IACI,gBAAgB;IAChB,8BAA8B;AAClC;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,eAAe;;AAEnB;;AAEA;IACI,WAAW;IACX,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,iBAAiB;IACjB,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,gBAAgB;IAChB,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,qBAAqB;IACrB,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;;;AAGrB","sourcesContent":[".article {\n    color: white;\n    align-items: center;\n    display: flex;\n    flex-direction: column;\n    max-height:0;\n    overflow: hidden;\n    transition: max-height 1s ease;\n\n}\n\n.article.open {\n    max-height:100vh;\n    transition: max-height 1s ease;\n}\n\n.article.close {\n    max-height:100vh;\n}\n\n.article.show {\n    max-height:none;\n\n}\n\n.article .content {\n    width: 80vw;\n    text-align:left;\n    line-height: 1.5;\n}\n\n.article .section-title {\n    font-size: xx-large;\n    font-weight: bold;\n    margin-top: 1rem;\n    text-align: center;\n}\n\n.article .code {\n    padding: 10px;\n    background-color: #3A3B3B;\n    color: lightgray;\n    border-radius: 5px;\n    margin: 20px;\n}\n\n.article h2 {\n    font-size: 24px;\n    font-weight:bolder;\n    margin-top: 20px;\n    margin-bottom: 10px;\n}\n\n.article li {\n    margin-left: 2rem;\n}\n\n.article a {\n    color: #ACACFF;\n}\n\n.Articles {\n    display:flex;\n    flex-direction:column;\n    color: #AF8D8C;\n}\n\n.Articles h1 {\n    margin-left:auto;\n    margin-right:auto;\n    \n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
