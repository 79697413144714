// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../img/background.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.theater {
    width:100%;
    display: grid;
    place-items:center;
    grid-template-areas: "inner-div";
}

.theater.h-100 {
    height:100%;
}

.theater.h-50v {
    height:50vh;
}

.theater-bg {
    
    height: inherit;
    width:100%;
    background-size: cover;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    filter: blur(2px);
    -webkit-filter: blur(2px);
    z-index:0;
    grid-area: inner-div;
}

.theater.peak .theater-bg {
    height: 85vh;
}

.theater-content {
    display:flex;
    flex-direction: column;
    text-shadow: 0px 0px 2px black;
    text-align: center;
    z-index:1;
    color: #FAFCF0;
    grid-area: inner-div;
    font-weight:bolder;
    max-width: 100%;
    width:100%;
    background: rgba(76, 76, 80, 0.75);
    align-items: center;
}

.theater-content .description, .theater-content h1 {
    width: 50vw;
}
.theater-content .description {
    padding: 10px;
}

.theater-content a {
    color: #CFCFBA !important;
}

.theater-content h1 {
    width:100%;
    text-align:center;
}

@media only screen and (max-width: 600px) {
    .theater-content .description {
        width: 80vw;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/css/Theater.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,aAAa;IACb,kBAAkB;IAClB,gCAAgC;AACpC;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;AACf;;AAEA;;IAEI,eAAe;IACf,UAAU;IACV,sBAAsB;IACtB,yDAAkD;IAClD,iBAAiB;IACjB,yBAAyB;IACzB,SAAS;IACT,oBAAoB;AACxB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,sBAAsB;IACtB,8BAA8B;IAC9B,kBAAkB;IAClB,SAAS;IACT,cAAc;IACd,oBAAoB;IACpB,kBAAkB;IAClB,eAAe;IACf,UAAU;IACV,kCAAkC;IAClC,mBAAmB;AACvB;;AAEA;IACI,WAAW;AACf;AACA;IACI,aAAa;AACjB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,UAAU;IACV,iBAAiB;AACrB;;AAEA;IACI;QACI,WAAW;IACf;AACJ","sourcesContent":[".theater {\n    width:100%;\n    display: grid;\n    place-items:center;\n    grid-template-areas: \"inner-div\";\n}\n\n.theater.h-100 {\n    height:100%;\n}\n\n.theater.h-50v {\n    height:50vh;\n}\n\n.theater-bg {\n    \n    height: inherit;\n    width:100%;\n    background-size: cover;\n    background-image: url('../../img/background.webp');\n    filter: blur(2px);\n    -webkit-filter: blur(2px);\n    z-index:0;\n    grid-area: inner-div;\n}\n\n.theater.peak .theater-bg {\n    height: 85vh;\n}\n\n.theater-content {\n    display:flex;\n    flex-direction: column;\n    text-shadow: 0px 0px 2px black;\n    text-align: center;\n    z-index:1;\n    color: #FAFCF0;\n    grid-area: inner-div;\n    font-weight:bolder;\n    max-width: 100%;\n    width:100%;\n    background: rgba(76, 76, 80, 0.75);\n    align-items: center;\n}\n\n.theater-content .description, .theater-content h1 {\n    width: 50vw;\n}\n.theater-content .description {\n    padding: 10px;\n}\n\n.theater-content a {\n    color: #CFCFBA !important;\n}\n\n.theater-content h1 {\n    width:100%;\n    text-align:center;\n}\n\n@media only screen and (max-width: 600px) {\n    .theater-content .description {\n        width: 80vw;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
