// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.listings {
    display:flex;
    flex-direction: column;
    width: 75vw;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 5.5rem;

}

.listing {
    border-top-style: solid;
    border-top-color: #A4A4A1;
    border-top-width:2px;
    font-size: x-large;
    display: flex;
    flex-direction: column;
    align-items: left;
    margin-top: 10px;
    color: #AA9AA0;
}

.listing .title {
    color: #BF8D8C !important;
    font-size: 24px;
    margin-top: 10px;
    font-weight:bold;
    width:fit-content;
    width:-moz-fit-content;
    text-align:start;
    padding:5px;
    border-radius: 3px;
}

.listing .content {
    display:flex;
    flex-direction: row;
    align-items:center;
    font-size: large;
}

.listing .description {
    flex-grow: 1;
    padding:10px;
}`, "",{"version":3,"sources":["webpack://./src/components/css/Listing.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,sBAAsB;IACtB,WAAW;IACX,iBAAiB;IACjB,kBAAkB;IAClB,sBAAsB;;AAE1B;;AAEA;IACI,uBAAuB;IACvB,yBAAyB;IACzB,oBAAoB;IACpB,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,iBAAiB;IACjB,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,yBAAyB;IACzB,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,iBAAiB;IACjB,sBAAsB;IACtB,gBAAgB;IAChB,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,YAAY;AAChB","sourcesContent":[".listings {\n    display:flex;\n    flex-direction: column;\n    width: 75vw;\n    margin-left: auto;\n    margin-right: auto;\n    padding-bottom: 5.5rem;\n\n}\n\n.listing {\n    border-top-style: solid;\n    border-top-color: #A4A4A1;\n    border-top-width:2px;\n    font-size: x-large;\n    display: flex;\n    flex-direction: column;\n    align-items: left;\n    margin-top: 10px;\n    color: #AA9AA0;\n}\n\n.listing .title {\n    color: #BF8D8C !important;\n    font-size: 24px;\n    margin-top: 10px;\n    font-weight:bold;\n    width:fit-content;\n    width:-moz-fit-content;\n    text-align:start;\n    padding:5px;\n    border-radius: 3px;\n}\n\n.listing .content {\n    display:flex;\n    flex-direction: row;\n    align-items:center;\n    font-size: large;\n}\n\n.listing .description {\n    flex-grow: 1;\n    padding:10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
