// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProgressBar {
    height: 40px;
    width: 100%;
    border-style: solid;
    border-width:1px;
    border-radius: 3px;
    padding:0;
    background-color: #BCBABA;
}

.ProgressBar .Bar {
    height: 100%;
    width:0;
    background-color: #CDFBFA;
    margin: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/css/ProgressBar.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,mBAAmB;IACnB,gBAAgB;IAChB,kBAAkB;IAClB,SAAS;IACT,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,OAAO;IACP,yBAAyB;IACzB,oBAAoB;AACxB","sourcesContent":[".ProgressBar {\n    height: 40px;\n    width: 100%;\n    border-style: solid;\n    border-width:1px;\n    border-radius: 3px;\n    padding:0;\n    background-color: #BCBABA;\n}\n\n.ProgressBar .Bar {\n    height: 100%;\n    width:0;\n    background-color: #CDFBFA;\n    margin: 0 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
