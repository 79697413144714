// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.topic {
    height: 100vh;
    width: 100vw;
    background-size:cover;
    display:flex;
    align-items:center;

}

.topic .content {
    width: 100vw;
    background: rgba(57, 57, 57, 0.75);
    padding:0;
    margin:0;
    padding: 10px;
    height:-moz-fit-content;
    height:fit-content;
}

.topic .title {
    margin-top:10px;
    margin-bottom:10px;
    padding:0;
}

.topic .children {
    margin-top:10px;
    margin-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/css/Topic.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,qBAAqB;IACrB,YAAY;IACZ,kBAAkB;;AAEtB;;AAEA;IACI,YAAY;IACZ,kCAAkC;IAClC,SAAS;IACT,QAAQ;IACR,aAAa;IACb,uBAAkB;IAAlB,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,SAAS;AACb;;AAEA;IACI,eAAe;IACf,mBAAmB;AACvB","sourcesContent":[".topic {\n    height: 100vh;\n    width: 100vw;\n    background-size:cover;\n    display:flex;\n    align-items:center;\n\n}\n\n.topic .content {\n    width: 100vw;\n    background: rgba(57, 57, 57, 0.75);\n    padding:0;\n    margin:0;\n    padding: 10px;\n    height:fit-content;\n}\n\n.topic .title {\n    margin-top:10px;\n    margin-bottom:10px;\n    padding:0;\n}\n\n.topic .children {\n    margin-top:10px;\n    margin-bottom: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
