// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ArticleEditor textarea {
    width:100%;
    height: 35vh;
    border-style: solid;
    border-color: lightgray;
    border-radius: 2px;
    border-width: 2px;
    padding: 0.4em 0.4em 0.4em 0;
    text-align: left;
}

.ArticleEditor .toolbar {
    width: 100%;
    height: 56px;
    display: flex;
}

.ArticleEditor .toolbar .btn {
    color: white;
    background-color: darkgreen;
    text-align: center;
    align-items:center;
    justify-content: center;
    display: flex;
    padding-left: 3px;
    padding-right: 3px;
}
.ArticleEditor .toolbar .btn:hover{
    background-color: #005400
}

.ArticleEditor .article .open {
    max-height:100vh;
}

.ArticleEditor .article .Close {
    max-height:100vh;
}

.ArticleEditor .article {
    max-height:100vh;
}`, "",{"version":3,"sources":["webpack://./src/components/css/ArticleEditor.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,YAAY;IACZ,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,iBAAiB;IACjB,4BAA4B;IAC5B,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,2BAA2B;IAC3B,kBAAkB;IAClB,kBAAkB;IAClB,uBAAuB;IACvB,aAAa;IACb,iBAAiB;IACjB,kBAAkB;AACtB;AACA;IACI;AACJ;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".ArticleEditor textarea {\n    width:100%;\n    height: 35vh;\n    border-style: solid;\n    border-color: lightgray;\n    border-radius: 2px;\n    border-width: 2px;\n    padding: 0.4em 0.4em 0.4em 0;\n    text-align: left;\n}\n\n.ArticleEditor .toolbar {\n    width: 100%;\n    height: 56px;\n    display: flex;\n}\n\n.ArticleEditor .toolbar .btn {\n    color: white;\n    background-color: darkgreen;\n    text-align: center;\n    align-items:center;\n    justify-content: center;\n    display: flex;\n    padding-left: 3px;\n    padding-right: 3px;\n}\n.ArticleEditor .toolbar .btn:hover{\n    background-color: #005400\n}\n\n.ArticleEditor .article .open {\n    max-height:100vh;\n}\n\n.ArticleEditor .article .Close {\n    max-height:100vh;\n}\n\n.ArticleEditor .article {\n    max-height:100vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
